import styled from '@emotion/styled';
import { Button, Stack, Typography } from '@mui/material';
import { isAxiosError } from 'axios';
import { useEffect, useRef, useState } from 'react';
import ReactOtpInput from 'react-otp-input';
import { getNonce, verifyAuthPhone, verifyPhoneNumber } from '~api/user';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import { useGlobalDispatch } from '~utils/container';

const TimerText = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.warning.main,
  textAlign: 'center',
}));

export const OtpCodeStep = ({ phoneDetail }: any) => {
  const [otpCode, setOtpCode] = useState<string>('------');
  const [remainingTime, setRemainingTime] = useState<number>(300);
  const [timeOut, setTimeOut] = useState<boolean>(false);
  const [resendCooldown, setResendCooldown] = useState<number>(0);
  const [error, setError] = useState<string>('');
  const dispatch = useGlobalDispatch();
  const { hidePopup, showSelectGenrePopup, showSuccessUpdatePhone } =
    useDispatchPopup();
  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 1) {
          setTimeOut(true);
          clearInterval(intervalId);
          setError('Expired time out, Please resend OTP!');
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (resendCooldown > 0) {
      const cooldownInterval = setInterval(() => {
        setResendCooldown((prevCooldown) => {
          if (prevCooldown <= 1) {
            clearInterval(cooldownInterval);
            return 0;
          }
          return prevCooldown - 1;
        });
      }, 1000);

      return () => clearInterval(cooldownInterval);
    }
  }, [resendCooldown]);

  const onSubmit = async () => {
    try {
      if (phoneDetail) {
        const response = await verifyAuthPhone(
          otpCode,
          phoneDetail.phoneNumber,
          phoneDetail.countryCode,
        );
        hidePopup();
        dispatch({
          type: 'user',
          payload: {
            phone: response.data.phone,
            transaction: response.data.transaction,
          },
        });
        if (response.data.transaction) {
          showSuccessUpdatePhone();
        } else {
          showSelectGenrePopup();
        }
      }
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        setError(err?.response?.data?.message);
      } else {
        setError('Undefined error');
      }
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const resendOtpCode = async () => {
    if (resendCooldown === 0) {
      try {
        const nonce: string = (await getNonce()).data.nonce;
        const response = await verifyPhoneNumber(
          phoneDetail.phoneNumber,
          nonce,
        );
        if (!response || !(response.status === 200)) {
          setError('Cannot resend OTP, please try again!');
        }
      } catch (err) {
        setError('Cannot resend OTP, please try again!');
      }

      setResendCooldown(60);
      setTimeOut(false);
      setRemainingTime(300);
      setError('');
    }
  };
  // UI Processing:
  const stackRef = useRef<any>(null);
  let myCalculatedWidth = 0;
  if (stackRef.current) {
    const stackWidth = stackRef.current.offsetWidth;
    const calculatedWidth = (0.8 * stackWidth) / 6;
    myCalculatedWidth = calculatedWidth;
  }
  return (
    <Stack ref={stackRef} spacing={3} alignItems={'center'}>
      <Typography
        textTransform={'uppercase'}
        mb={3}
        fontWeight={'700'}
        fontSize={{ xs: 20 }}
        textAlign={'center'}
      >
        VERIFY YOUR PHONE NUMBER
      </Typography>
      <Stack alignItems={'center'} spacing={1}>
        <Typography textAlign={'center'} fontSize={{ xs: 15 }}>
          Enter the code sent to your mobile phone
        </Typography>
        <TimerText mb={3} fontWeight={'700'} fontSize={{ xs: 20 }}>
          {formatTime(remainingTime)}
        </TimerText>
        <ReactOtpInput
          value={otpCode}
          onChange={setOtpCode}
          containerStyle={{
            width: '100%',
            minHeight: 80,
            justifyContent: 'center',
          }}
          numInputs={6}
          inputType="text"
          renderSeparator={<span style={{ width: 10 }}></span>}
          renderInput={(props) => (
            <input
              {...props}
              defaultValue={'-'}
              style={{
                background: '#1D212D',
                width: myCalculatedWidth,
                aspectRatio: '1',
                textAlign: 'center',
                color: 'white',
              }}
            />
          )}
        />
        {error && <Typography color="error">{error}</Typography>}
      </Stack>
      <Button
        variant="text"
        color="warning"
        sx={{
          textTransform: 'none',
          fontWeight: 'bold',
        }}
        onClick={resendOtpCode}
        disabled={resendCooldown > 0}
      >
        {resendCooldown > 0
          ? `Resend Code (${resendCooldown}s)`
          : 'Resend Code'}
      </Button>
      <Button
        color="warning"
        variant="contained"
        onClick={onSubmit}
        sx={{ textTransform: 'capitalize' }}
        fullWidth
        disabled={timeOut}
      >
        VERIFY
      </Button>
    </Stack>
  );
};
