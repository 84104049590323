import React, { useMemo, useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
//
import { Tab, Tabs } from '~components/atoms';
import { ConfirmationModal } from '~components/organisms';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
//
import { updateOnBoardingStep } from '~api';
import {
  FEATURE_FLAGS_TYPE,
  ON_BOARDING_INFO_STEP,
  useGlobalDispatch,
  useGlobalState,
} from '~utils';
import Rewards from './tabs/rewards';
import { AddLivecasts, Payout, Profile, Reports } from './tabs';
import { TABS, TabsValue } from './types';
import ShareProfileModal from '~components/organisms/popup/share-profile-modal';
import { useFeatureFlag } from '~hooks/useFeatureFlag';
import ArchivedVideos from './tabs/archived-videos';
interface ManageProfileProps {
  onClose: () => void;
  activeTab: TabsValue;
  changeActiveTab: (initialTab: TabsValue) => void;
  isOnBoarding?: boolean;
}

const ManageProfile = ({
  onClose,
  activeTab,
  changeActiveTab,
  isOnBoarding = false,
}: ManageProfileProps) => {
  const {
    user: { name },
  } = useGlobalState();
  const dispatch = useGlobalDispatch();
  const { showBookingProducer } = useDispatchPopup();
  const [nextTab, setNextTab] = useState<TabsValue | null>(null);
  const [isConfirmationWindowOpen, setIsConfirmationWindowOpen] =
    useState(false);
  const [isDataInActiveTabUnsaved, setIsDataInActiveTabUnsaved] =
    useState(false);
  const [isShareProfileModalOpen, setIsShareProfileModalOpen] = useState(false);
  const { isEnable } = useFeatureFlag();
  const isEnablePayout = isEnable(FEATURE_FLAGS_TYPE.PAYOUT_FEATURE);
  // TODO: Temporarily using this FF for archived video
  const isEnableArchivedVideo = isEnable(
    FEATURE_FLAGS_TYPE.NEW_ARTIST_SIGN_UP_FLOW,
  );
  const tabsData = [
    TABS.PROFILE,
    TABS.ADD_LIVECASTS,
    TABS.REWARDS,
    TABS.REPORTS,
  ];
  const tabs = useMemo(() => {
    let mTabs = tabsData;
    if (isOnBoarding) {
      return [TABS.PROFILE];
    }
    if (isEnablePayout) {
      mTabs = [...mTabs, TABS.PAYOUT];
    }
    if (isEnableArchivedVideo) {
      mTabs = [...mTabs, TABS.ARCHIVED_VIDEOS];
    }
    return mTabs;
  }, [isOnBoarding]);

  const openConfirmationWindow = () => setIsConfirmationWindowOpen(true);
  const closeConfirmationWindow = () => setIsConfirmationWindowOpen(false);

  const changeStatusIsDataSaved = (status: boolean) =>
    setIsDataInActiveTabUnsaved(status);

  const closeManageProfileWindow = () => {
    if (isDataInActiveTabUnsaved) {
      setNextTab(null);
      openConfirmationWindow();
      return;
    }
    onClose();
  };

  const changeTab = (tab: TabsValue) => {
    if (isDataInActiveTabUnsaved) {
      setNextTab(tab);
      openConfirmationWindow();
      return;
    }
    changeActiveTab(tab);
  };

  const confirmGoToAnotherTab = () => {
    if (!nextTab) return;
    changeActiveTab(nextTab);
    setNextTab(null);
    changeStatusIsDataSaved(false);
    closeConfirmationWindow();
  };
  const confirmCloseManageProfileWindow = () => {
    closeConfirmationWindow();
    onClose();
  };

  const nextStepForProfile = async () => {
    await updateOnBoardingStep(ON_BOARDING_INFO_STEP.DONE);
    dispatch({
      type: 'user',
      payload: { onBoardingInfoStep: ON_BOARDING_INFO_STEP.DONE },
    });
    openShareProfileModal();
  };

  const openShareProfileModal = () => setIsShareProfileModalOpen(true);
  const closeShareProfileModal = () => {
    setIsShareProfileModalOpen(false);
    onClose();
    showBookingProducer();
  };

  return (
    <>
      <ConfirmationModal
        title={`Do you want to close ${activeTab} tab without saving?`}
        open={isConfirmationWindowOpen}
        onClose={closeConfirmationWindow}
        confirmAction={
          nextTab ? confirmGoToAnotherTab : confirmCloseManageProfileWindow
        }
      />
      <ShareProfileModal
        open={isShareProfileModalOpen}
        onClose={closeShareProfileModal}
        artistName={name}
        showHeader={true}
      />
      <Container>
        <Header>
          <Title>Manage profile</Title>
          {!isOnBoarding && (
            <CloseWrapper onClick={closeManageProfileWindow}>
              <CloseText>Close</CloseText>
              <img
                src={'/assets/images/cross.svg'}
                className="img-fluid"
                alt="Cross"
              />
            </CloseWrapper>
          )}
        </Header>
        <CustomTabs
          value={activeTab}
          onChange={(event, value) => changeTab(value)}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab}
              value={tab}
              label={tab}
              disableRipple
              disabled={isOnBoarding}
            />
          ))}
        </CustomTabs>
        {activeTab === TABS.PROFILE && (
          <Profile
            changeStatusIsDataSaved={changeStatusIsDataSaved}
            isOnBoarding={isOnBoarding}
            nextStep={nextStepForProfile}
          />
        )}
        {activeTab === TABS.ADD_LIVECASTS && (
          <AddLivecasts changeStatusIsDataSaved={changeStatusIsDataSaved} />
        )}
        {activeTab === TABS.REWARDS && <Rewards />}
        {activeTab === TABS.REPORTS && <Reports />}
        {activeTab === TABS.PAYOUT && (
          <Payout changeStatusIsDataSaved={changeStatusIsDataSaved} />
        )}
        {activeTab === TABS.REFERRALS && <Center>Referrals</Center>}
        {activeTab === TABS.ARCHIVED_VIDEOS && <ArchivedVideos />}
      </Container>
    </>
  );
};

export default ManageProfile;

const Center = styled(Box)(() => ({
  margin: '0 auto',
}));

const Container = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));
const Header = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
}));
const Title = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  color: theme.palette.text.primary,
  textTransform: 'uppercase',

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.h2.fontSize,
  },
}));
const CloseWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  right: 0,
  cursor: 'pointer',
  gap: 4,
}));
const CloseText = styled(Typography)(({ theme }) => ({
  margin: 0,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.subtitle2.fontSize,
  color: '#FFFFFF',
  cursor: 'pointer',
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },
}));
const CustomTabs = styled(Tabs)(({ theme }) => ({
  padding: '20px 0',
  [theme.breakpoints.up('sm')]: {
    padding: '40px 0',
  },
}));
