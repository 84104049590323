import {
  Box,
  Divider,
  Stack,
  StackProps,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import { FC } from 'react';
import { TextEllipsis } from '~components/atoms';
import Avatar from '~components/organisms/avatar';

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: ` ${theme.spacing(0.25)} ${theme.spacing(1)}`,
  backgroundColor: theme.palette.warning.main,
  borderRadius: theme.shape.borderRadius,
}));

const StyledEmail = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  textTransform: 'lowercase',
  color: alpha(theme.palette.common.white, 0.8),
  fontWeight: '300',
}));

type Props = StackProps & {
  userName: string;
  avatarKey: string; // using username
  userEmail?: string;
  avatarSize?: number;
  type?: string;
  maxWidth?: number | string | Record<string, number>;
  phone?: string;
};

const EnhancedAccountAvatar: FC<Props> = (props) => {
  const {
    userEmail,
    userName,
    avatarKey,
    avatarSize = 40,
    type,
    maxWidth,
    phone,
    ...rest
  } = props;

  return (
    <Stack direction={'row'} spacing={1} alignItems={'center'} {...rest}>
      <Stack gap={1}>
        <Box sx={{ width: avatarSize, height: avatarSize }}>
          <Avatar
            username={avatarKey}
            sx={{
              maxWidth: '100%',
            }}
          />
        </Box>
        {type && <StyledLabel variant="caption">{type}</StyledLabel>}
      </Stack>
      <Stack
        height={'100%'}
        justifyContent={'space-between'}
        overflow={'hidden'}
      >
        <TextEllipsis
          text={userName}
          variant="body1"
          fontSize={{ xs: 16 }}
          disabled={!userEmail}
          sx={{
            textTransform: 'initial',
            cursor: 'inherit',
            fontWeight: '500',
            lineHeight: 1.2,
            maxWidth,
          }}
        />
        {userEmail && (
          <StyledEmail variant="body2" color="text.disabled" fontWeight={'300'}>
            {userEmail}
          </StyledEmail>
        )}
        <Divider sx={{ marginTop: 0.2 }} />
        {phone && (
          <TextEllipsis
            text={phone}
            variant="body1"
            fontSize={{ xs: 16 }}
            sx={{
              textTransform: 'initial',
              cursor: 'inherit',
              fontWeight: '500',
              maxWidth,
              marginTop: 1,
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default EnhancedAccountAvatar;
