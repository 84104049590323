import React, { useEffect, useState } from 'react';
import { Box, styled } from '@mui/material';

import {
  ArrowContainer,
  NextArrowButtons,
  PrevArrowButtons,
  SlideWrapper,
} from '~components/pages/home/styles';

import Slider from 'react-slick';
import { getImageURL } from '~utils/awsUtils';
import useBreakpoints from '~hooks/useBreakpoints';
import { ItemInSlide } from './item-in-slide';
import { CarouselDots } from '~components/molecules';

const VideoList = ({ total }: { total: number }) => {
  const PROCESSED_SLIDES_IN_ROW_IN_RESPONSE = {
    xs: 1,
    sm: 2,
    md: 2,
    lg: 4,
    xl: 4,
  };

  const breakpoints = useBreakpoints();
  const ROWS_IN_RESPONSE = {
    xs: 3,
    sm: 3,
    md: 3,
    lg: 3,
    xl: 3,
  };
  const [limit, setLimit] = useState(
    PROCESSED_SLIDES_IN_ROW_IN_RESPONSE[breakpoints.active] *
      ROWS_IN_RESPONSE[breakpoints.active],
  );
  const RESPONSIVE = [
    {
      breakpoint: 576,
      settings: {
        arrows: false,
        slidesPerRow: PROCESSED_SLIDES_IN_ROW_IN_RESPONSE.xs,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        slidesPerRow: PROCESSED_SLIDES_IN_ROW_IN_RESPONSE.md,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesPerRow: PROCESSED_SLIDES_IN_ROW_IN_RESPONSE.md,
      },
    },
  ];
  const [offset, setOffset] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  //   const { data: endedEvents, refetch } = useQuery({
  //     queryKey: [QUERY_KEYS.LANDING_EVENTS_ENDED, limit, offset],
  //     queryFn: () =>

  //     refetchOnWindowFocus: false,
  //   });

  // const renderData = useMemo(() => {
  //   const emptySlides = generateArrayOfInitialSlides(total);
  //   if (!endedEvents) return emptySlides;
  //   return [
  //     ...emptySlides.slice(0, offset),
  //     ...endedEvents,
  //     ...emptySlides.slice(offset + limit),
  //   ];
  //   return;
  // }, [limit, offset, total]);

  useEffect(() => {
    setLimit(
      PROCESSED_SLIDES_IN_ROW_IN_RESPONSE[breakpoints.active] *
        ROWS_IN_RESPONSE[breakpoints.active],
    );
  }, [breakpoints.active]);

  useEffect(() => {
    if (total < offset) {
      setOffset(Math.ceil(total / limit) * limit - limit);
    }
    // refetch();
  }, [limit, offset, total]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    slidesPerRow: PROCESSED_SLIDES_IN_ROW_IN_RESPONSE.xl,
    rows: ROWS_IN_RESPONSE[breakpoints.active],
    beforeChange: (oldIndex: number, newIndex: number) => {
      setIsDragging(true);
      setTimeout(() => {
        setIsDragging(false);
      }, 500);
      setOffset(newIndex * limit);
    },
    prevArrow: (
      <ArrowContainer>
        <PrevArrowButtons />
      </ArrowContainer>
    ),
    nextArrow: (
      <ArrowContainer>
        <NextArrowButtons />
      </ArrowContainer>
    ),
    responsive: RESPONSIVE,
    ...CarouselDots({
      sx: {
        mt: 1,
        color: 'white',
      },
    }),
  };

  return (
    <Box sx={{ mt: 4 }}>
      <CustomSlider {...settings}>
        {[...Array(12)]
          .map((e, i) => {
            return {
              id: `${i}`,
              imageUrl: 'https://picsum.photos/300/200',
              artistUserName: `artist${i}`,
              userName: `08/10/2024 Livecast ${i}`,
              thumbnail: 'https://picsum.photos/300/200',
            };
          })
          .map((data: any, index) => {
            console.log('current data', data);
            const { id, artistUserName, userName, imageUrl, thumbnail } = data;
            return offset > index || index >= offset + limit ? (
              <SlideWrapper key={id} />
            ) : (
              <ItemInSlide
                key={id}
                eventId={id}
                artistUserName={artistUserName}
                userName={userName}
                imageUrl={getImageURL(thumbnail || imageUrl)}
                isDragging={isDragging}
              />
            );
          })}
      </CustomSlider>
    </Box>
  );
};

export default VideoList;

const CustomSlider = styled(Slider)(({ theme }) => ({
  minHeight: 220,
  '.slick-dots': {
    paddingTop: 20,
    li: {
      margin: 0,
      paddingTop: 10,
    },
    div: {
      margin: 'auto',
    },
    '.slick-active div': {
      backgroundColor: theme.palette.text.primary,
    },
  },
}));
