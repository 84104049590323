import React, { ReactNode } from 'react';
import { atcb_action } from 'add-to-calendar-button';
import { getDateAndTime, getLocalTimeZone } from '~utils/dateTimeUtils';
import { Stack, StackProps, Typography, styled } from '@mui/material';
import { AppleCalendar, GoogleCalendar } from '../../icons';
import useInAppBrowserChecker from '~hooks/useInAppBrowserChecker';
import useGa4EventSender from '~hooks/useGa4EventSender';

type CalendarContentProps = {
  icon?: undefined | 'google' | 'apple';
  text: string;
};

type CalendarOptions =
  | 'Apple'
  | 'Google'
  | 'iCal'
  | 'Microsoft365'
  | 'MicrosoftTeams'
  | 'Outlook.com'
  | 'Yahoo';

type Props = CalendarContentProps & {
  name: string;
  description: string;
  startDateTime: string;
  endDateTime: string;
  text?: string | ReactNode;
  options?: CalendarOptions[];
  eventId: string;
  artistId: string;
} & StackProps;

const StyledCalendarWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.spacing(0.5),
  backgroundColor: theme.palette.grey[600],
  justifyContent: 'flex-start',
  alignItems: 'center',
  cursor: 'pointer',
}));

const ICONS = {
  google: <GoogleCalendar />,
  apple: <AppleCalendar />,
};

const CalendarContent = ({ text, icon }: CalendarContentProps) => (
  <Stack
    direction={'row'}
    justifyContent={'center'}
    alignItems={'center'}
    spacing={1}
  >
    {icon !== undefined && ICONS[icon] ? ICONS[icon] : null}
    <Typography
      variant="caption"
      textAlign={'left'}
      sx={{
        userSelect: 'none',
      }}
      fontSize={8}
      lineHeight={'9px'}
      maxWidth={32}
    >
      {text}
    </Typography>
  </Stack>
);

const AddToCalendar = ({
  name,
  description,
  startDateTime,
  endDateTime,
  icon,
  text,
  options = [
    'Apple',
    'Google',
    'iCal',
    'Microsoft365',
    'MicrosoftTeams',
    'Outlook.com',
    'Yahoo',
  ],
  eventId,
  artistId,
  ...rest
}: Props) => {
  const isInAppBrowser = useInAppBrowserChecker();
  const { sendDownloadCalendarClickEvent } = useGa4EventSender();

  const handleClick = () => {
    const { date: startDate, time: startTime } = getDateAndTime(startDateTime);
    const { date: endDate, time: endTime } = getDateAndTime(endDateTime);
    const API_HOST = process.env.REACT_APP_API_HOST;
    sendDownloadCalendarClickEvent(
      artistId,
      eventId,
      options[0]?.toUpperCase(),
    );
    if (isInAppBrowser && options[0] === 'Apple') {
      atcb_action({
        name,
        description,
        startDate,
        endDate,
        startTime,
        endTime,
        options,
        timeZone: getLocalTimeZone(),
        iCalFileName: 'URSA-Event',
        icsFile: `${API_HOST}/events/generate-ics?event_id=${eventId}`,
        bypassWebViewCheck: true,
      });
    } else {
      atcb_action({
        name,
        description,
        startDate,
        endDate,
        startTime,
        endTime,
        options,
        timeZone: getLocalTimeZone(),
        iCalFileName: 'URSA-Event',
      });
    }
  };

  return (
    <StyledCalendarWrapper onClick={handleClick} {...rest}>
      <CalendarContent text={text} icon={icon} />
    </StyledCalendarWrapper>
  );
};

export default AddToCalendar;
