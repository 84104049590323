import { FC, useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Menu,
  Stack,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import {
  FEATURE_FLAGS_TYPE,
  ON_BOARDING_INFO_STEP,
  POPUP_TYPE,
  PopupTypeValues,
  useGlobalDispatch,
  useGlobalState,
  USER_TYPE,
} from '~utils/index';
import AccountMenu from './account-menu';
import AccountAvatar from './account-avatar';
import AccountDrawer from './account-drawer';
import AccountActions from './account-actions';
import FanSettingDashboardModal from '~components/organisms/popup/setting-dashboard-modal/fan-setting-dashboard-modal';
import ArtistSettingDashboardModal from '~components/organisms/popup/setting-dashboard-modal/artist-setting-dashboard-modal';
import { ManageProfileContext } from '~providers/ManageProfileProvider';
import useBreakpoints from '~hooks/useBreakpoints';
import { TABS } from '~components/manage-profile/types';
import useTikTokEvent from '~hooks/useTiktokPixel';
import { useFeatureFlag } from '~hooks/useFeatureFlag';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';

const StyledMenuWrapper = styled(Box)<{ artist: boolean }>(
  ({ artist, theme }) => ({
    overflow: 'hidden',
    width: artist ? '100%' : 'unset',
    [theme.breakpoints.up('lg')]: {
      flex: artist ? 3 : 'none',
    },
  }),
);

type Props = {
  isArtistPage?: boolean;
  disabledLiveBtn?: boolean;
  onLogout: () => Promise<void>;
  onLeaveEvent?: () => void;
  onStartLive?: () => void;
};

const enum MODAL_NAME {
  NONE,
  SETTING_DASHBOARD,
}

const Account: FC<Props> = (props) => {
  const {
    disabledLiveBtn,
    onLogout,
    onLeaveEvent = () => undefined,
    onStartLive = () => undefined,
    isArtistPage = true,
  } = props;
  const { openManageProfile } = useContext(ManageProfileContext);
  const dispatch = useGlobalDispatch();
  const { isLg } = useBreakpoints();
  const globalState = useGlobalState();
  const {
    user: {
      id: userId,
      displayUsername: userDisplayName,
      name: userName,
      username: avatarKey,
      onBoardingInfoStep,
      type,
      isPartner,
      phone,
      genre,
      imageUrl,
      transaction,
    },
    app: { popup },
    config: { isArtistLive },
    // TODO need to define data type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = globalState as Record<string, any>;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [activeModal, setActiveModal] = useState<MODAL_NAME>(MODAL_NAME.NONE);
  const [isInitializedAfterLogin, setIsInitializedAfterLogin] =
    useState<any>(null);
  const open = Boolean(anchorEl);
  const isGuest = !userId && !popup;
  const isArtist = type === USER_TYPE.ARTIST;
  const popoverId = open ? 'account-popover' : undefined;
  const { isEnable } = useFeatureFlag();
  const isEnableNewSignUpFlow = isEnable(
    FEATURE_FLAGS_TYPE.NEW_ARTIST_SIGN_UP_FLOW,
  );
  const {
    showUpdatePhoneNumberPopup,
    showSelectGenrePopup,
    showUploadAvatarPopup,
  } = useDispatchPopup();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseModal = () => {
    setActiveModal(MODAL_NAME.NONE);
  };

  const onOpenSettingDashboard = () => {
    setActiveModal(MODAL_NAME.SETTING_DASHBOARD);
  };

  const handleTogglePopup = (popup: PopupTypeValues) => {
    handleClose();
    dispatch({
      type: 'app',
      payload: { popup },
    });
  };
  const tiktokPixel = useTikTokEvent();
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isGuest) {
      tiktokPixel.trackClickEvent('click_sign_up_btn');
      handleTogglePopup(POPUP_TYPE.LOGIN);
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleLiveAction = () => {
    if (isArtistLive) {
      onLeaveEvent();
      return;
    }
    onStartLive();
  };

  useEffect(() => {
    if (isGuest) {
      setIsInitializedAfterLogin(false);
    }
    if (
      isArtist &&
      onBoardingInfoStep &&
      onBoardingInfoStep !== ON_BOARDING_INFO_STEP.DONE
    ) {
      if (isEnableNewSignUpFlow) {
        if (phone == null && onBoardingInfoStep == 'PROFILE') {
          showUpdatePhoneNumberPopup();
        } else if (genre == null && onBoardingInfoStep == 'PROFILE') {
          showSelectGenrePopup();
        } else if (imageUrl == null && onBoardingInfoStep == 'PROFILE') {
          showUploadAvatarPopup();
        }
      } else {
        openManageProfile({ initialTab: TABS.PROFILE, isOnBoarding: true });
      }
    } else if (isEnableNewSignUpFlow && isArtist && !phone && !transaction) {
      showUpdatePhoneNumberPopup(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInitializedAfterLogin === false) {
      if (
        isArtist &&
        !phone &&
        !transaction &&
        onBoardingInfoStep === ON_BOARDING_INFO_STEP.DONE
      ) {
        showUpdatePhoneNumberPopup(true);
        setIsInitializedAfterLogin(true);
      }
    }
  }, [
    isArtist,
    phone,
    transaction,
    isInitializedAfterLogin,
    onBoardingInfoStep,
  ]);

  return (
    <>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'flex-end'}
        spacing={1}
      >
        {!isLg && (
          <AccountDrawer
            avatarKey={avatarKey}
            isArtistPage={isArtistPage}
            onOpenSettingDashboard={onOpenSettingDashboard}
            onLogout={onLogout}
            disabledLiveBtn={disabledLiveBtn}
            onLeaveEvent={onLeaveEvent}
            onStartLive={onStartLive}
          />
        )}
        {isLg && (
          <>
            <AccountActions
              disabled={disabledLiveBtn}
              onClick={handleLiveAction}
            />
            <StyledMenuWrapper artist={isArtist && !!isArtistLive}>
              <Button
                aria-describedby={popoverId}
                disableTouchRipple
                variant="text"
                onClick={handleOpen}
                sx={{
                  height: 40,
                  width: isArtist ? 'inherit' : 'initial',
                }}
              >
                {isGuest ? (
                  <Typography
                    variant="body2"
                    fontWeight={'500'}
                    color="text.primary"
                    sx={{
                      textTransform: 'capitalize',
                      cursor: 'pointer',
                      ':hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    Log In | Sign Up
                  </Typography>
                ) : (
                  <AccountAvatar
                    sx={{ width: 'inherit' }}
                    userName={userName || userDisplayName}
                    avatarKey={avatarKey}
                    maxWidth={!isArtist || !isArtistLive ? 220 : 'none'}
                  />
                )}
              </Button>

              <Menu
                open={open}
                id={popoverId}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflowX: 'visible',
                    overflowY: 'auto',
                    filter: (theme) =>
                      `drop-shadow(-4px 4px 20px ${alpha(
                        theme.palette.common.white,
                        0.05,
                      )})`,
                  },
                }}
                MenuListProps={{
                  disablePadding: true,
                }}
              >
                <AccountMenu
                  isArtistPage={isArtistPage}
                  onOpenSettingDashboard={onOpenSettingDashboard}
                  onClose={handleClose}
                  onLogout={onLogout}
                />
              </Menu>
            </StyledMenuWrapper>
          </>
        )}
      </Stack>

      {activeModal === MODAL_NAME.SETTING_DASHBOARD && isArtist && (
        <ArtistSettingDashboardModal
          isPartnerShip={isPartner}
          onClose={handleCloseModal}
          open={true}
          title="Audio/video dashboard"
          enableAEC={!isArtistLive}
          enableRatio={!isArtistLive}
        />
      )}
      {activeModal === MODAL_NAME.SETTING_DASHBOARD && !isArtist && (
        <FanSettingDashboardModal
          onClose={handleCloseModal}
          open={true}
          title="Audio/video dashboard"
        />
      )}
    </>
  );
};

export default Account;
