import { LandingPageEvent } from '~types/landing-page';
import {
  Box,
  BoxProps,
  IconButton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  SlideRatioContainer,
  SlideBorder,
} from '~components/pages/home/styles';
import { ThumbNail } from '~components/pages/home/ThumbNail';
import { MoreHoriz } from '@mui/icons-material';

type Props = {
  isDragging: boolean;
  eventId: string;
} & Pick<LandingPageEvent, 'imageUrl' | 'artistUserName' | 'userName'> &
  BoxProps;

export const ItemInSlide = ({
  imageUrl,
  artistUserName,
  userName,
  ...boxProps
}: Props) => {
  return (
    <Box {...boxProps}>
      <SlideBorder>
        <SlideRatioContainer>
          <ThumbNail src={imageUrl} />
          <SliderContent />
        </SlideRatioContainer>
      </SlideBorder>
      <Stack
        width={'100%'}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        marginTop={'auto'}
      >
        <Typography
          sx={{
            paddingLeft: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {userName || artistUserName}
        </Typography>
        <IconButton>
          <MoreHoriz />
        </IconButton>
      </Stack>
    </Box>
  );
};

const SliderContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  padding: 12,
}));
