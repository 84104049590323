import React, { FC, useContext } from 'react';
// @mui
import {
  alpha,
  Divider,
  Stack,
  StackProps,
  styled,
  Typography,
} from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import BookmarkAddedOutlinedIcon from '@mui/icons-material/BookmarkAddedOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import WalletOutlinedIcon from '@mui/icons-material/WalletOutlined';
import MovieOutlinedIcon from '@mui/icons-material/MovieOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
// routes
import { useNavigate, useParams } from 'react-router-dom';
// hooks
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import { useBoolean } from '~hooks/useBoolean';
import { useFeatureFlag } from '~hooks/useFeatureFlag';
// utils
import {
  inMemoryJWTService,
  useGlobalDispatch,
  useGlobalState,
} from '~utils/index';
import {
  FEATURE_FLAGS_TYPE,
  POPUP_TYPE,
  PopupTypeValues,
  USER_TYPE,
} from '~utils/constants';
import { getBookingProducerLink } from '~utils/configUtils';
import { showErrorToaster } from '~utils/toasterNotification';
import { convertToArtist } from '~api/fan';
// components
import { ArtistName } from '~components/atoms';
import { AccountMenuItem, DialogConfirm } from '~components/molecules';
import { ManageProfileContext } from '~providers/ManageProfileProvider';
// types
import { TABS } from '~components/manage-profile/types';
import { RoutesPages } from '../../../router/types';
//
import AccountAvatar from './account-avatar';
import AccountActions from './account-actions';
import useAccountBalances from '~hooks/useAccountBalances';
import useAccount from '~hooks/useAccount';
import { Phone } from '@mui/icons-material';
import EnhancedAccountAvatar from './enhanced-account-avatar';

const StyledRoot = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.grey[900],
  padding: theme.spacing(3.5),
  borderRadius: theme.shape.borderRadius,
  minWidth: 288,
  maxWidth: 304,
  [theme.breakpoints.up('md')]: {
    maxWidth: 400,
  },
}));
const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  textTransform: 'uppercase',
  color: alpha(theme.palette.common.white, 0.6),
}));

type Props = StackProps & {
  isArtistPage?: boolean;
  disabledLiveBtn?: boolean;
  onLogout: () => void;
  onClose: () => void;
  onLeaveEvent?: () => void;
  onStartLive?: () => void;
  onOpenSettingDashboard: () => void;
};

const AccountMenu: FC<Props> = (props) => {
  const { isEnable } = useFeatureFlag();
  const isEnableBlockedUserFeature = isEnable(FEATURE_FLAGS_TYPE.BLOCKED_USER);
  const isEnableNewSignup = isEnable(
    FEATURE_FLAGS_TYPE.NEW_ARTIST_SIGN_UP_FLOW,
  );
  const isEnableNewLiveCoinSystem = isEnable(
    FEATURE_FLAGS_TYPE.SEPARATE_LIVE_COINS,
  );
  const {
    disabledLiveBtn,
    onLogout,
    onLeaveEvent,
    onStartLive,
    onClose,
    onOpenSettingDashboard,
    isArtistPage = true,
    ...rest
  } = props;

  const { username: artistNameParam } = useParams();
  const navigate = useNavigate();

  const dialogConvertToArtist = useBoolean();

  const {
    showChangePasswordPopup,
    showChangeUsernamePopup,
    showChangeEmailPopup,
    showBecomePartnershipPopup,
    showBuyLiveCoinPopup,
    showUpdatePhoneNumberPopup,
  } = useDispatchPopup();
  const { openManageProfile } = useContext(ManageProfileContext);
  const dispatch = useGlobalDispatch();
  const globalState = useGlobalState();
  const { walletBalance, availableEarnedCoin } = useAccountBalances();
  const { isArtistAccount, originalType, isViewer, isHost } = useAccount();
  const {
    artist: { name, username: artistUsername },
    user: {
      displayUsername: userDisplayName,
      username: avatarKey,
      name: userName,
      email: userEmail,
      phone,
      isPartner,
    },
    config: { isArtistLive },
    // TODO need to define data type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = globalState as Record<string, any>;

  // const isFan = type === USER_TYPE.FAN;
  //
  // const isOwnerArtist = type === USER_TYPE.ARTIST;

  const handleTogglePopup = (popup: PopupTypeValues) => {
    onClose();
    dispatch({
      type: 'app',
      payload: { popup },
    });
  };

  const handleBuyLiveCoin = () => {
    onClose();
    showBuyLiveCoinPopup(isViewer && !!artistUsername);
  };

  const openEvents = () => {
    onClose();
    openManageProfile({ initialTab: TABS.ADD_LIVECASTS });
  };

  const handleLiveAction = () => {
    onClose();
    if (isArtistLive) {
      onLeaveEvent && onLeaveEvent();
      return;
    }
    onStartLive && onStartLive();
  };

  const handleManageProfile = () => {
    onClose();
    openManageProfile();
  };

  const handleBookingProducer = () => {
    onClose();
    window.open(getBookingProducerLink(), '_blank');
  };

  const handleBecomePartner = () => {
    onClose();
    showBecomePartnershipPopup();
  };

  const handleClickMyChannel = () => {
    window.location.href = `/${avatarKey}`;
  };

  const handleConfirmConvertToArtist = async () => {
    try {
      const res = await convertToArtist();
      if (res.data) {
        const {
          accessToken,
          refreshToken,
          expiresIn,
          user: { username },
        } = res.data;
        inMemoryJWTService.setToken(accessToken, expiresIn);
        inMemoryJWTService.setRefreshToken(refreshToken);
        window.location.href = `/${username}`;
      }
    } catch (error) {
      showErrorToaster(error);
    }
  };

  const handleClickChangePassword = () => {
    onClose();
    showChangePasswordPopup();
  };

  const handleClickChangeUsername = () => {
    onClose();
    showChangeUsernamePopup();
  };

  const handleClickUpdateEmail = () => {
    onClose();
    showChangeEmailPopup();
  };

  const handleClickUpdatePhoneNumber = () => {
    onClose();
    showUpdatePhoneNumberPopup();
  };

  return (
    <>
      <StyledRoot spacing={3} {...rest}>
        {isEnableNewSignup && originalType === USER_TYPE.ARTIST ? (
          <EnhancedAccountAvatar
            alignItems="flex-start"
            avatarSize={48}
            avatarKey={avatarKey}
            userName={userName || userDisplayName}
            userEmail={userEmail}
            type={originalType}
            phone={phone}
          />
        ) : (
          <AccountAvatar
            alignItems="flex-start"
            avatarSize={48}
            avatarKey={avatarKey}
            userName={userName || userDisplayName}
            userEmail={userEmail}
            type={originalType}
          />
        )}

        <Divider />
        {isArtistPage && isHost && (
          <>
            <Stack
              spacing={1.5}
              sx={{
                display: {
                  xs: 'block',
                  lg: 'none',
                },
              }}
            >
              <ArtistName
                sx={{ display: 'block', maxWidth: 'none' }}
                variant="body2"
              >
                LIVE WITH {name || artistNameParam}
              </ArtistName>
              <AccountActions
                sx={{
                  display: 'block',
                }}
                fullWidth
                disabled={disabledLiveBtn}
                onClick={handleLiveAction}
              />
            </Stack>
          </>
        )}

        <Stack spacing={0.5}>
          <StyledTitle
            variant="body1"
            mb={{
              xs: 1,
              md: 0,
            }}
          >
            My account
          </StyledTitle>
          {(isHost || originalType === USER_TYPE.ARTIST) && (
            <AccountMenuItem
              disabled={artistNameParam === avatarKey}
              onClick={handleClickMyChannel}
              icon={<OndemandVideoOutlinedIcon />}
              text="My Channel"
            />
          )}
          <AccountMenuItem
            onClick={() => {
              onClose();
              onOpenSettingDashboard();
            }}
            icon={<MovieOutlinedIcon />}
            text="Audio/Video Dashboard"
          />
          <AccountMenuItem
            onClick={handleBuyLiveCoin}
            icon={<MonetizationOnOutlinedIcon />}
            text="Buy livecoins"
          />
          <AccountMenuItem
            icon={<WalletOutlinedIcon />}
            text={
              <>
                Wallet: {walletBalance}{' '}
                <Typography
                  fontWeight={'500'}
                  component={'span'}
                  textTransform={'lowercase'}
                >
                  lc
                </Typography>
              </>
            }
          />
          <AccountMenuItem
            hidden={!isArtistAccount || !isEnableNewLiveCoinSystem}
            icon={<WalletOutlinedIcon />}
            text={
              <>
                Cash Out: {availableEarnedCoin}{' '}
                <Typography
                  fontWeight={'500'}
                  component={'span'}
                  textTransform={'lowercase'}
                >
                  lc
                </Typography>
              </>
            }
          />
          <AccountMenuItem
            hidden={!isArtistAccount}
            onClick={openEvents}
            icon={<OndemandVideoOutlinedIcon />}
            text="Add Upcoming Livecasts"
          />
        </Stack>
        <Divider />
        <Stack spacing={0.5}>
          <StyledTitle
            variant="body1"
            mb={{
              xs: 1,
              md: 0,
            }}
          >
            Profile
          </StyledTitle>
          <AccountMenuItem
            hidden={!isArtistAccount}
            onClick={handleManageProfile}
            icon={<AccountCircleOutlinedIcon />}
            text="Manage profile"
          />
          <AccountMenuItem
            onClick={handleClickUpdateEmail}
            icon={<MailOutlineIcon />}
            text="Update Email"
          />
          {isEnableNewSignup && originalType === USER_TYPE.ARTIST && (
            <AccountMenuItem
              onClick={handleClickUpdatePhoneNumber}
              icon={<Phone />}
              text={'Change Phone Number'}
            />
          )}
          <AccountMenuItem
            onClick={handleClickChangePassword}
            icon={<LockOutlinedIcon />}
            text="Change Password"
          />
          <AccountMenuItem
            onClick={handleClickChangeUsername}
            icon={<BadgeOutlinedIcon />}
            text="Change Username"
          />
          <AccountMenuItem
            hidden={!isViewer}
            onClick={() => handleTogglePopup(POPUP_TYPE.EDIT_PROFILE_POPUP)}
            icon={<AccountCircleOutlinedIcon />}
            text="Add/Edit Profile Picture"
          />
        </Stack>

        <Divider />
        <Stack spacing={0.5}>
          <StyledTitle
            variant="body1"
            mb={{
              xs: 1,
              md: 0,
            }}
          >
            MORE
          </StyledTitle>
          <AccountMenuItem
            onClick={() => navigate(RoutesPages.FOLLOWING_ARTIST)}
            icon={<GroupOutlinedIcon />}
            text="Artists I Follow"
          />
          <AccountMenuItem
            hidden={isViewer || isPartner}
            onClick={handleBecomePartner}
            icon={<HandshakeOutlinedIcon />}
            text="Become a Partner"
          />
          <AccountMenuItem
            hidden={isArtistAccount}
            onClick={dialogConvertToArtist.onTrue}
            icon={<HandshakeOutlinedIcon />}
            text="Convert to Artist"
          />
          <AccountMenuItem
            hidden={!isArtistAccount}
            onClick={handleBookingProducer}
            icon={<BookmarkAddedOutlinedIcon />}
            text="Book a Producer"
          />
          <AccountMenuItem
            hidden={!isArtistAccount || !isEnableBlockedUserFeature}
            onClick={() => navigate(RoutesPages.UNBLOCK_USERS)}
            icon={<BlockOutlinedIcon />}
            text="Block/Unblock Users"
          />
        </Stack>
        <Divider />
        <AccountMenuItem
          onClick={() => {
            onClose();
            onLogout();
          }}
          icon={<LogoutIcon />}
          text="Sign Out"
        />
      </StyledRoot>
      <DialogConfirm
        open={dialogConvertToArtist.value}
        title="Confirmation"
        message="Are you sure you want to convert to Artist account?"
        onClose={dialogConvertToArtist.onFalse}
        onCancel={dialogConvertToArtist.onFalse}
        onConfirm={handleConfirmConvertToArtist}
      />
    </>
  );
};

export default AccountMenu;
