import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Box, Grid, Stack, styled, Typography } from '@mui/material';
import { QUERY_KEYS } from '~constants/query-keys';
import { useGlobalDispatch, useGlobalState, USER_TYPE } from '~utils/index';
import { getRewardPageData } from '~api/reward';
import { WrapperBlock } from '~components/manage-profile/components';
import { DatePicker, Select } from '~components/atoms';
import VideoList from './VideoList';

const Container = styled(Stack)(() => ({
  width: '100%',
}));

Container.defaultProps = {
  spacing: {
    xs: 4,
    sm: 40,
  },
};

const ArchivedVideos = () => {
  const dispatch = useGlobalDispatch();
  const globalState = useGlobalState();
  const {
    artist: { id: artistId },
    user: { id: userId, type },
  } = globalState as {
    app: Record<string, any>;
    artist: { id: string };
    user: { id: string; type: string };
  };

  const fetchingArtistId = useMemo(() => {
    return type === USER_TYPE.ARTIST ? userId : artistId;
  }, [artistId, type, userId]);

  useQuery({
    queryKey: [QUERY_KEYS.ARTIST_REWARDS],
    queryFn: () => getRewardPageData(fetchingArtistId),
    enabled: !!fetchingArtistId,
    onSuccess: (data) => {
      dispatch({
        type: 'app',
        payload: {
          activeCampaign: data.activeCampaign,
          upcomingCampaigns: data.upcomingCampaigns ?? [],
          previousCampaigns: data.previousCampaigns ?? [],
          totalPreviousCampaign: data.totalPreviousCampaign,
          totalUpcomingCampaign: data.totalUpcomingCampaign,
        },
      });
    },
  });

  return (
    <Stack
      width={'100%'}
      spacing={{
        xs: 0.5,
        sm: 5,
      }}
    >
      <WrapperBlock
        title={'Archived Videos'}
        description={
          'IMPORTANT: We only save videos for 2 weeks unless you choose to add them to your profile.'
        }
        sx={{ '.MuiBox-root': { border: 'none !important' } }}
      >
        {/* {errorMessages.map((message, index) => {
        return <ErrorBox key={index}>{message}</ErrorBox>;
      })} */}
        <Wrapper>
          <Grid container>
            <FormRow container item>
              <Grid item xs={12} sm={1}>
                <CustomLabel>Time</CustomLabel>
              </Grid>
              <Grid container item xs={12} sm={11} spacing={1}>
                <FilterWrapper item xs={12} sm={6} md={6} lg={3} xl={3}>
                  <CustomLabel
                    sx={{ mr: '8px', color: 'rgba(255, 255, 255, 0.6)' }}
                  >
                    From
                  </CustomLabel>
                  <DatePicker placeholder="Select Start Date" />
                </FilterWrapper>
                <FilterWrapper item xs={12} sm={6} md={6} lg={3} xl={3}>
                  <CustomLabel
                    sx={{ mr: '8px', color: 'rgba(255, 255, 255, 0.6)' }}
                  >
                    To
                  </CustomLabel>
                  <DatePicker placeholder="Select End Date" />
                </FilterWrapper>
                <FilterWrapper item xs={12} sm={3} md={3} lg={3} xl={3}>
                  <SortByOption
                    defaultValue={'MOST_RECENT'}
                    items={[
                      {
                        label: 'Sort by: Most Recent',
                        value: 'MOST_RECENT',
                      },
                      {
                        label: 'Sort by: Oldest',
                        value: 'OLDEST',
                      },
                    ]}
                  />
                </FilterWrapper>
              </Grid>
            </FormRow>
          </Grid>
          <VideoList total={12} />
        </Wrapper>
      </WrapperBlock>
    </Stack>
  );
};

export default ArchivedVideos;

const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 2,
}));

const FormRow = styled(Grid)(() => ({
  padding: '8px 0',
}));

const FilterWrapper = styled(Grid)(() => ({
  display: 'inline-flex',

  '& > .MuiBox-root:nth-of-type(1)': {
    marginRight: '8px',
  },
}));

const CustomLabel = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
}));

const SortByOption = styled(Select)(({ theme }) => ({
  '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.action.active}`,
  },
}));
